//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------
$font-family-base: "Century Gothic",Arial,"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
$font-size-base: .875rem;

//---------------------------------------------
// 2. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

$primary: #243257;
$secondary: #668ea5;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

//---------------------------------------------
// 3. Bootstrap Links
//---------------------------------------------

$link-decoration: none;
$link-hover-decoration: underline;

//---------------------------------------------
// 4. Bootstrap Globals
//---------------------------------------------
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 5. Bootstrap Buttons
//---------------------------------------------

$btn-close-color: white;

//---------------------------------------------
// 6. Bootstrap Containers
//---------------------------------------------

$container-max-widths: ( sm: 540px, md: 750px, lg: 1130px, xl: 1430px, xxl: 1440px );

//---------------------------------------------
// 7. Bootstrap Components
//---------------------------------------------

$card-border-radius: 0;

//---------------------------------------------
// 8. Tables and Grids
//---------------------------------------------

$table-accent-bg: #f9f9f9 !default;
$table-active-bg: #f5f5f5 !default;
$table-hover-bg: #f5f5f5 !default;
$table-border-color: #ddd !default;

//---------------------------------------------
// 9. Modals
//---------------------------------------------

//$modal-content-bg: $primary;
$modal-footer-bg: $primary;

//---------------------------------------------
// 10. Nav Tabs and Links
//---------------------------------------------
$nav-tabs-link-active-bg: #7b7b7b;