.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formContainer {
    box-shadow: 4px 8px 16px #243257;
    margin: 20vh auto;
    padding: 20px;
    border-radius: 0px 0px 4px 4px;
}

input {
    box-shadow: none !important;
    outline: none;
}

.login {
    background: #243257 !important;
    border-color:black;
}

    .login hover {
        background: #243200 !important;
        border-color: black;
    }

#formHeading {
    background: #243257;
    color: white;
    margin: -20px;
    border-radius: 4px 4px 0px 0px
}