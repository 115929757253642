//---------------------------------------------
// App-specific Styles 
//
// Pattern is:
// 
// #module-root {
//   #[App Name] {
//      [All styles for this app]
//   }
// }
//---------------------------------------------

#module-root {
    #home {
        ul {
            padding-left: 0px;
            list-style-type: none;

            li:not(:first-child) {
                padding-top: 10px;
            }

            > li > ul {
                margin-top: 10px;
            }

            > li > ul > li {
                padding-left: 20px;
            }
        }

        #about-content {
            margin-top: 40px;
            padding: 0px;

            h3 {
                margin-top: 0px;
                color: #FF6A00;
            }

            .row {
                margin: 0px;
            }

            > .row > div {
                margin-top: 35px;
                padding: 0px;
                padding-right: 15px;
            }

            .about-content-widget {
                color: #ffffff;
                background: #243257;
                padding: 20px;
                display: block;
                text-align: center;
                font-size: 50px;
                float: left;
                margin-right: 20px;
                width: 106px;
                height: 106px;
            }
        }

        #above-the-fold-ctas {
            background: rgba(255, 255, 255, 0.6);
            margin-top: 25px;
            padding-top: 15px;

            h3 {
                text-align: center;
                color: #243257;
                margin: 5px 0px;
            }

            div {
                padding: 0px;
            }

            /*div:not(:first-child) {
                padding-left: 15px;
            }*/

            > .container-fluid {
                margin-top: 10px;
                padding: 0px 15px;
            }

            .action-blocks {
                background: #243257;
            }

            .cta-block {
                background-color: #FFF;

                a {
                    display: block;
                    text-align: left;
                    color: black !important;
                    text-decoration: none !important;
                }

                a:hover {
                    color: darkgrey !important;
                    text-decoration: underline;
                }
            }
        }

        .footer {
            text-align: center;
            background: #243257;
            color: #FFFFFF;
            margin-top: 20px;
            padding: 20px 0px;

            img {
                width: 400px;
                height: auto;
                display: block;
                margin: 0px auto;
            }
        }

        @media only screen and (max-width: 500px) {
            a {
                font-size: 1.25em;
                margin-top: 15px;
            }

            #above-the-fold-ctas #cta-tools {
                margin-top: 25px;
            }

            #about-content {
                padding: 0px;

                h1 {
                    font-size: 25px;
                }

                .about-content-widget {
                    font-size: 25px;
                    margin-right: 10px;
                    width: 53px;
                    height: 53px;
                    padding: 12px 0px 0px 0px;
                }
            }

            .footer img {
                width: 300px;
            }
        }

        @media only screen and (max-width: 768px) {
            #above-the-fold-ctas div:not(:first-child) {
                padding-left: 0px;
            }

            #above-the-fold-ctas .cta-block > div {
                padding: 0px 0px 15px 15px;
            }
        }

        @media only screen and (max-width: 1500px) {
            #about-content {
                padding: 0px 15px;
            }
        }
    }
}
