@import './Content/Style/scss/bootstrap-override-variables';
@import 'bootstrap/scss/bootstrap';
@import './Content/Style/scss/font-awesome';
@import './Content/Style/scss/bootstrap-3-panels';
@import './Content/Style/scss/toastr';

//---------------------------------------------
// Header Nav Styles - To Be Removed (maybe)
//---------------------------------------------

.navbar-logo {
    padding-left: 0;
    width: 225px;
}
.navbar-env {
    flex-shrink: 1; 
    color: red;
}
.navbar-user {    
    max-width: 350px; 
    flex-shrink: 1; 
}
.d-flex.align-items-center {
    height: 100%;
}

#essentials-logo {
    background-image: url('essentialsv3-logo.png');
    background-repeat: no-repeat;
    background-position: left top;
    margin: 15px;
    width: 200px;
    height: 30px;
}

.main-nav {
    padding: 0 7px;
}

// hide the caret
.dropdown-toggle:after {
    //display: none !important;
}

.nav-item a:hover {
    background-color: #243257;
    color: white;
}

.nav-item.dropdown .dropdown-menu {
    padding: 0;
    border-radius: 0;
    width: max-content;
}

// adds hover for dropdown items but doesn't quite work 
.nav-item.dropdown:hover .dropdown-menu {
    //display: block;
}

.group-menu-name-with-icon {
    margin-left: 5px;
}

.user-account-options {
    padding: 10px 10px 0 0;
}
.impersonate-lookup {
    position:absolute;
    right: 10px;
}
.link-button {
    background: unset;
    border: none;
    color: #243257;
    width: 100%;
    border-radius: 0;
    text-align: left;
}
.link-button:hover {
    background-color: #ddd;
    color: #243257;
}
.borderless-button {
    background: unset;
    border: none;
}
.borderless-button-sm {
    font-size:10px;
}
.borderless-button:hover {
    opacity: .7;
}
.borderless-button:active {
    margin-top: 1px;
    margin-left: 1px;
}
.popover {
    padding: 10px;
}
.popover-nopadding {
    padding: 0!important;
}
.btn-nowrap {
    text-wrap: nowrap;
}
#main-nav {
    .impersonated-user {
        color: darkorange;
    }

    #account-dropdown.dropdown-toggle:after {
        display: none;
    }

    #btn-show-trace-toggle.dropdown-toggle:after {
        display: none;
    }

    .tools-menu {
        .dropdown-menu {
            padding: 0;
            width: 300px;
            border-radius: 0;
        }

        .list-group {
            border-radius: 0;

            .list-group-item {
                border-left: 0;
                border-right: 0;
                padding: 0;

                .link-button {
                    padding: 10px;
                }
            }
        }

        .heading {
            padding: 15px;
            color: #fff;
            background-color: #243257;
            width: 100%;
            font-weight: normal;
            font-size: 16px;
        }

        #impersonate-searchfield {
            border-radius: 0;
            margin: 5px 15px 10px 15px;
            width: 270px;
        }

        #tracing-toggle {
            height:36px;
            width: 64px;
        }
    }
}

//---------------------------------------------
// Global app custom styles
//---------------------------------------------
#module-root {
    //padding: .875rem;
    padding: .5rem 1.25rem;
}
#reset-password-form {
    padding: .875rem;
}

#btn-back-to-top {
    color: #fff;
    position: fixed;
    bottom: 60px;
    right: 20px;
    display: none;
    background: #243257;
}

.modal-body {
    //overflow-x: scroll;
}

// Create more of these as desired.
.modal-95w {
    max-width: none !important;
    width: 95%;
}

.link-disabled:hover {
    background:initial;
}
.link-disabled a {
    cursor: default;
}
.link-disabled a:hover {
    text-decoration: none;
}

//---------------------------------------------
// Bootstrap Global Style Overrides
//  -- not defined by variables --
//---------------------------------------------

h1, h2 {
    color: #243257;
}

// footer has a variable in bootstrap, but header does not
.modal-header {
    background-color: $primary;
    color: white;
}

label {
    font-weight: 700;
    margin-bottom: 5px;
}

.panel-body {
    padding: 1.2rem;
}

.form-control {
    appearance: auto;
}

.btn-primary {
    border-color: #000;
}

.btn-secondary {
    color: #fff !important;
}

a.btn:focus.no-shadow {
    box-shadow: none;
}

button.close {
    background: transparent;
    border: 0;
    font-weight: 700;
    font-size: 1.5rem;
    float: right;
    line-height: 1;
}

#impersonation-form h5, #tracing-menu h5 {
    color: white;
    padding: .5rem 1rem;
}

a.list-group-item:hover, a.list-group-item:focus, button.list-group-item:hover, button.list-group-item:focus {
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5;
    transition: all .3s ease 0s;
}

/* Backwards compatiblity since these classes were removed in Bootstrap 5 */
.form-group {
    margin-bottom: 1rem;
}

.card-header-alt {
    background-color: #243257;
    color: #fff;
}

.text-right {
    text-align: right;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col {
    padding-left: 5px;
    padding-right: 5px;
}

label {
    margin-bottom: 0.5rem;
}

.nav-tabs {
    .nav-link:hover, .nav-tabs .nav-link:focus {
        background-color: #243257;
    }

    .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #7b7b7b;
        background-color: #f4f4f2;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
    }

    > li > a:hover, .nav > li > a:hover {
        color: white;
    }
}

//---------------------------------------------
// SQL Tracing custom styles
//---------------------------------------------
#tracing-buttons {
    button {
        height: 35px;
        margin-top: 8px;
    }
    label {
        margin-top: 8px;
    }
}
#sql-trace-modal {
    .title {
        font-weight: bold;
    }

    .trace-message > :not(:first-child) {
        margin-left: 15px;
    }

    .technical-details label {
        margin-right: 5px;
    }
}